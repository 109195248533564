module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-XXH1C0G0HX"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tenpaMk2's blog","short_name":"tenpaMk2's blog","start_url":"/","background_color":"#f7f0eb","theme_color":"#F47414","display":"standalone","icon":"src/@tenpamk2/gatsby-theme-figure-blog/images/bio.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fd95f208425c7a2e5b3136eab1bb3fde"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"linkImagesToOriginal":true,"withWebp":true,"withAvif":false,"quality":90,"backgroundColor":"transparent","srcSetBreakpoints":[480,1024,2048],"showCaptions":false,"markdownCaptions":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@tenpamk2/gatsby-theme-figure-blog/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"","descriptionTruncate":true,"externalLinks":[{"name":"Asahiwa.jp(よつばとフィギュア)","url":"https://asahiwa.jp"},{"name":"ふぃぎゅる！","url":"http://blog.livedoor.jp/nobuno88/"},{"name":"fig-memo","url":"https://fig-memo.com"},{"name":"Analographicsworks","url":"https://analographics.net/archives/category/figure-review"},{"name":"雪の降る空に","url":"https://yukinofurusorani.livedoor.blog"},{"name":"mattintosh note","url":"https://mattintosh.hatenablog.com/archive/category/フィギュア"},{"name":"affilabo.com","url":"https://affilabo.com/category/figure/"},{"name":"QP's blog","url":"https://qp-works.com/category/figure/"},{"name":"F9のフィギュア棚","url":"https://fantia.jp/fanclubs/164836/"},{"name":"葵的 美少女フィギュアの世界","url":"https://note.com/ao_figure/"},{"name":"よよのデジタルショーケース","url":"https://yoyotandigitalshowcase.hatenablog.com"},{"name":"an-gallery","url":"https://an-gallery.com"},{"name":"フィギュアレン","url":"http://figuren-toy.com"},{"name":"FREETIME","url":"https://freetime.mixh.jp"}],"locale":"ja-JP","optionsGatsbyPluginSharp":{"defaults":{"formats":["webp"],"breakpoints":[480,1024,2048]}},"optionsGatsbyRemarkImages":{"quality":90,"srcSetBreakpoints":[480,1024,2048],"withAvif":false},"optionsGatsbyTransformerRemark":{"excerpt_separator":"<!-- more -->"},"postPath":"","rssNeedFullContent":false,"rssPruneLength":256,"rssTruncate":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
